<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('pages.all-menus') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <b-list-group>
              <b-list-group-item v-for="lang in languages" :key="lang.id" :class="'d-flex align-items-center enabled-' + lang.enabled"
                  :to="{ name : 'MenuForLanguage', params: { languageShortName: lang.shortName }}">
                <b-avatar variant="primary" :src="lang.icon" class="mr-4"></b-avatar>
                <h3 class="display-5">{{ lang.name }}</h3>
              </b-list-group-item>
            </b-list-group>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import { BAvatar } from 'bootstrap-vue'
export default {
  name: 'AllMenus',
  components: { NavBar, BAvatar },
  data: function() {
    return {
      languages: [],
      showOverlay: true
    }
  },
  created: async function() {
    await this.$http.get("/cms/api/languages/enabled/", { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.languages = r.data;
      })
      .catch(error => { this.handleError(error); })
    this.showOverlay = false;
  }
}
</script>
<style></style>
